<template>
  <div class="exchangeMember">
    <Header title="兑换中心" />
    <div class="inputBox">
      <van-field class="codeInput" v-model="exchangeCode">
        <template #button>
          <div class="exchangeBtn" @click="confirmExchange">兑换</div>
        </template>
      </van-field>
      <div class="inputLabel">请输入兑换码</div>
    </div>
    <div class="cell">
      <div class="title">兑换码</div>
      <div class="title">奖励</div>
      <div class="title">日期</div>
    </div>
    <div class="cellList" v-if="list.length > 0">
      <div class="cellBox" v-for="item in list" :key="item.code">
        <div class="cellItem">{{ item.code }}</div>
        <div class="cellItem">{{ item.authority }}</div>
        <div class="cellItem">{{ item.updatedAt | time }}</div>
      </div>
    </div>
    <div class="noDataCellList" v-else>
      <span>暂无兑换记录</span>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header';
import { Field, Toast } from 'vant';
import { exchageCode, exchangeList } from '@/api/mine';
export default {
  name: 'ExchangeMember',
  components: {
    Header,
    [Field.name]: Field,
  },
  data() {
    return {
      exchangeCode: '',
      list: [],
    };
  },
  created() {
    this.getExchangeList();
  },
  methods: {
    async getExchangeList() {
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(exchangeList, null);
        if (ret.code === 200 && ret.data) {
          this.list = ret.data;
          // this.list = [{ code: 123213, authority: 'safasd', updatedAt: 0 }];
        } else if (ret.tip) {
          Toast(ret.tip || '获取数据异常');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('获取数据异常');
      }
    },
    async confirmExchange() {
      if (!this.exchangeCode) {
        Toast('请输入兑换码');
        return;
      }
      let req = {
        code: this.exchangeCode,
      };
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(exchageCode, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch('user/getUserInfo');
          this.getExchangeList();
          Toast('兑换成功');
        } else if (ret.tip) {
          Toast(ret.tip || '兑换失败');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('兑换失败');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.exchangeMember {
  padding: 0 20px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .inputBox {
    position: relative;
    // width: 335px;
    height: 58px;
    margin-bottom: 12px;
    margin-top: 24px;
    // margin: 24px auto 12px;
    .inputLabel {
      position: absolute;
      top: 4px;
      left: 24px;
      font-size: 12px;
      color: rgba(116, 116, 116, 0.4);
    }
  }
  .codeInput {
    // width: 355px;
    // height: 58px;
    border-radius: 10px;
    background: rgba(248, 248, 249, 0.7);
    // margin: 0 auto;
    padding: 10px 18px 10px 24px;
    box-sizing: border-box;
    /deep/ .van-field__control {
      font-size: 14px;
      color: #747474;
    }

    .exchangeBtn {
      height: 38px;
      width: 87px;
      border-radius: 10px;
      font-size: 14px;
      color: #fff;
      background-image: linear-gradient(to right, #f74a82, #fca984);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // .inputBox {
  //   width: 335px;
  //   height: 58px;
  //   margin: 0 auto;
  //   background: rgba(248, 248, 249, 0.7);
  //   border-radius: 10px;
  //   padding: 0 18px 0 24px;
  //   box-sizing: border-box;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }
  .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 335px;
    height: 34px;
    // margin: 0 auto;
    border-radius: 10px 10px 0 0;
    background: #ffd6df;
    // margin-bottom: 8px;
    .title {
      flex: 1;
      font-size: 12px;
      text-align: center;
    }
    // .title:first-of-type {
    //   flex: 1.5;
    // }
  }
  .cellList {
    overflow-y: auto;
    text-align: center;
    height: calc(100vh - 172px);
    .cellBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cellItem {
        flex: 1;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #797979;
      }
    }
    .cellBox:nth-child(2n) {
      background: #f0f0f0;
    }
  }
  .noDataCellList {
    height: calc(100vh - 172px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main {
    padding: 37px 12px;
    .exchangeBox {
      border-radius: 6px;
      overflow: hidden;
      /deep/ .van-field__label {
        font-size: 16px;
        width: unset;
        margin-right: unset;
      }
    }
    .exchangBtn {
      margin: 28px 0 47px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      background-color: rgb(255, 103, 143);
      font-size: 16px;
      text-align: center;
      border-radius: 4px;
    }
    .exchangeTable {
      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .title {
          flex: 2;
          font-size: 16px;
          text-align: center;
        }
        .title:first-of-type {
          flex: 1.5;
        }
      }
      .cellList {
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        span {
          display: inline-block;
          margin-top: 50px;
        }
        .cellBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(143, 123, 200, 0.06);
          .cellItem {
            flex: 2;
            font-size: 14px;
            height: 47px;
            line-height: 47px;
            text-align: center;
            background-color: #fff;
            color: rgb(102, 102, 102);
            overflow: hidden;
            white-space: nowrap;
          }
          .cellItem:first-of-type {
            flex: 1.5;
          }
          .cellItem:last-of-type {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
